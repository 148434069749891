/* HomePage.css */
body {
	background: linear-gradient(to bottom, orange, blue);
	background-repeat: no-repeat; /* added to prevent vertical tiling */
	background-size: cover; /* added to stretch the background to cover the viewport */
	margin: 0;
	padding: 0;
	font-family: Arial, sans-serif;
}

.container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
}

.logo {
	display: block;
	margin: 0 auto;
	text-align: center;
	animation: spin 3s linear infinite;
	transform-style: preserve-3d;
}

.logo img {
	max-width: 200px;
	height: auto;
}

.video-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}

.links {
	margin-top: 50px;
	text-align: center;
}

.links a {
	display: inline-block;
	margin: 0 20px;
	color: white;
	font-weight: bold;
	text-decoration: none;
}

.links a:hover {
	text-decoration: underline;
}

@keyframes spin {
	0% { transform: rotateY(0deg); }
	100% { transform: rotateY(360deg); }
}
/* Include all your CSS styles here */
